import React from 'react'
import './Contentsection.css'

const Contentsection = props => (
    <div className="contentSection">
        <h3>{props.title}</h3>
        <h4>{props.sub}</h4>
        <p>{props.txt}</p>
    </div>
)

export default Contentsection