import React from 'react'
import './Sectionheader.css'

const Sectionheader = props => (
    <div className="Sectionheader">
        <div className="divider"></div>
        <h2>{props.title}</h2>
        <h4>I talk about things</h4>
    </div>
)

export default Sectionheader