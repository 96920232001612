import React from "react"
import { Link } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"
import Header from "../components/header"
import Contentsection from "../components/Contentsection"
import Sectionheader from "../components/Sectionheader"

const IndexPage = () => (
  /* this is the start of something*/
  <Layout>
    <div>
    <Header/>
    <div className="Hero">
      <div className="HeroGroup">
        <img src={require('../images/profile-pic.png')} width="100px"/>
        <h1>Hello Humans!</h1>
        <p>My name is Dean Broadley, I'm a BIPOC South African born designer and speaker working to leave things more human than I found them.</p>
        <img src={require('../images/short-div.svg')} width="100px"/>


      </div>
      <div className="Contentsection">
        <Contentsection 
          title="Career"
          sub="A bit on who I am and what I've done"
          txt=""
          />
          <div>
            <p>I've been an interaction designer for companies like Mxit and run large teams in the building of the practice of Design in enterprises like <Link to="https://www.absa.co.za/personal/" target="_blank">Absa Group</Link> , (previously Barclays Africa) and have recently joined as the Executive Head of Design at <Link to="https://www.vodacom.co.za/" target="_blank">Vodacom.</Link></p>
          </div>

        <Contentsection 
          title="Community"
          sub="Working with broader community of humans"
          txt=""/>
          <p>In an effort to help support the growth and diversity of the people in our design community I assist and advise in the organisation of conferences and meetups such as <Link to="https://pixelup.co.za/" target="_blank">Pixel Up!</Link>, and also help to support and connect other communities together such as <Link to="https://www.meetup.com/She-Can-Do/" target="_blank">She Can Do.</Link></p>
      </div>

    </div>
    
    <Sectionheader title="Speaking Engagements"/>
    <div className="Contentsection">
      <Contentsection 
            title="Past Speaking Gigs"
            sub=""
            txt=""
            />
      <Link to="https://www.eventbrite.com/e/dropbox-design-speaker-series-tickets-116040254525" target="_blank">Dropbox Design speaker series</Link> ~ Leading through Limbo <br/>
      <Link to="https://disrupthr.co/vimeo-video/build-the-humans-the-products-will-follow-dean-broadley-disrupthr-talks/" target="_blank">HR Disrupt, Cape Town</Link> ~ Build the humans, The products will follow. <br/>
      <Link to="https://pixelup.co.za/" target="_blank">Pixel Up! 2020</Link> ~ Leading Humans through Limbo <br/>
      <Link to="https://sugsa.org.za/event/cpt-sugsa-ux-community-mash-up/" target="_blank">SUGSA Community Mashup</Link> ~ Multiplying the value of your agile team with Design<br/>
      <Link to="https://events.offerzen.com/" target="_blank">MERGE Conf. Cape Town, Johannesburg</Link> ~ Build the humans, The products will follow. <br/>
      <Link to="https://www.offerzen.com/" target="_blank">Offerzen, South Africa</Link> <a href="https://www.offerzen.com/blog/interview-dean-broadley-designing-humans-self-awareness-builds-better-tech" target="_blank">~ Podcast: Why Self-awareness builds better tech</a><br/>
      <Link to="https://www.evensi.us/design-lecture-dean-broadley-dropbox-hq/308388556" target="_blank">Dropbox Design, San Francisco 2019</Link> ~ Not Black enough to be White. <br/>
      <p>Facebook, London ~ 2017 Behind Enemy Lines</p>
      <Link to="https://pixelup.co.za/" target="_blank">Pixel Up!, Cape Town 2018, 2019</Link> ~ So you think you're a hedgehog, Designing Humans Report: Design in South Africa <br/>
    </div>

    <div className="Contentsection">
      <Contentsection 
            title="Upcoming Talks"
            sub=""
            txt=""
            />
      <Link to="https://rosenfeldmedia.com/designopssummit2020/" target="_blank">Rosenfeld Design Ops Summit 2020</Link> ~ Not Black Enough to Be White <br/>
    </div>
      
    </div>

    <SEO title="Design Leader, Speaker & Full-Time Human" />
   
  </Layout>
)

export default IndexPage
